import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Login = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await login(emailOrUsername, password);
      console.log('Login successful, redirecting to dashboard');
      navigate('/dashboard'); // Redirect to the dashboard after successful login
    } catch (error) {
      console.error('Error during login:', error);
      setError(error.response?.data?.message || 'Login failed');
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Login</h1>
      <p style={styles.blurb}>Please enter your email/username and password to login.</p>
      <form onSubmit={handleSubmit} style={styles.form}>
        {error && <p style={styles.error}>{error}.<br />Did you forgot password? Try this <Link to="/forgot-password">link</Link>.</p>}
        <input
          type="text"
          value={emailOrUsername}
          onChange={(e) => setEmailOrUsername(e.target.value)}
          placeholder="Email or Username"
          style={styles.input}
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>Login</button>
      </form>
      <p style={styles.link}>
        Not registered yet? <Link to="/register">Register here</Link>
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '500px',
    margin: '50px auto 0',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  blurb: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#555',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  button: {
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  link: {
    textAlign: 'center',
    marginTop: '10px',
  },
};

export default Login;
