import React, {useEffect, useState, useContext} from 'react';
import axios from '../utils/service';
import AuthContext from '../context/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import Spinner from "./Spinner";
import moment from 'moment';

const AdminPanelLessonsEditor = () => {
    const {user} = useContext(AuthContext);
    const [lessons, setLessons] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchUsers = async () => {
            if (user && user.role === 'admin') {
                const {data} = await axios.get(`/admin/lessons`, {
                    headers: {Authorization: `Bearer ${user.token}`},
                });
                setLessons(data);
            }
        };
        fetchUsers();
    }, [user]);

    const deleteLesson = async (lessonId) => {
        const answer = window.confirm("Are you sure you want to delete?");
        if(answer) {
            const resp = await axios.delete(`/admin/lessons/${lessonId}`);
            console.log(resp);
        }
    };

    const styles = {
        container: {
            padding: '20px',
        },
        editButton: {
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '5px',
        },
        deleteButton: {
            backgroundColor: '#ff1f33',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '5px',
        },
        addButton: {
            marginBottom: '20px',
            padding: '10px 15px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
        }, table: {
            width: '100%', borderCollapse: 'collapse', marginTop: '20px',
        }, th: {
            borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#333', color: '#fff',
        }, td: {
            borderBottom: '1px solid #ddd', padding: '10px',
        }
    };


    return (
        <div style={styles.container}>
            <h1>Admin Panel</h1>
            <h2>Lessons</h2>
            {!lessons ? (
                <Spinner/>
            ) : (
                <>
                    <button onClick={() => navigate('/admin/lessons/add')} style={styles.addButton}>Add Lesson</button>
                    <table style={styles.table}>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {lessons.map((lesson) => (<tr key={lesson._id}>
                            <td style={styles.td}>{lesson._id.slice(-8)}</td>
                            <td style={styles.td}>{lesson.title}</td>
                            <td style={styles.td}>
                                <button style={styles.editButton} onClick={() => navigate(`/admin/lessons/${lesson._id}`)}>Edit</button>
                                <button style={styles.deleteButton} onClick={() => deleteLesson(lesson._id)}>delete</button>
                            </td>
                        </tr>))}
                        </tbody>
                    </table>
                </>
            )}
        </div>);
};
export default AdminPanelLessonsEditor;
