import React, { useState, useRef } from "react";

const TermsOfService = ({ onAccept }) => {
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const scrollableRef = useRef(null);

    const handleScroll = () => {
        const element = scrollableRef.current;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            setIsScrolledToEnd(true);
        }
    };

    return (
        <div style={{marginTop: '10px', marginBottom: '10px'}}>
            <div
                ref={scrollableRef}
                onScroll={handleScroll}
                style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    padding: "16px",
                    marginBottom: "2px",
                    textAlign: "justify"
                }}
            >
                <h2>Terms of Service for Wahler Analytics</h2>
                <h3>Effective Date: 10/23/2024</h3>
                <p>Welcome to Wahler Analytics. By accessing or using our website (wahleranalytics.com) or services, you agree to comply with and be bound by these Terms of Service ("Terms"). Please review these Terms carefully. If you do not agree with these Terms, you should not use our website or services.</p>
    1. Acceptance of Terms
    By using Wahler Analytics’ website or services, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. You will also be required to separately agree to our Confidentiality Agreement, where applicable.
    2. Changes to the Terms
    Wahler Analytics reserves the right to update or modify these Terms at any time. We will notify users of any material changes by updating the “Effective Date” above. Continued use of our website or services after such changes constitutes your acceptance of the revised Terms.
    3. Description of Services
    Wahler Analytics provides data engineering services, consulting, and strategies for Texas Hold'em poker analytics. We may expand, modify, or discontinue any of our services at any time without notice.
    4. User Responsibilities
    As a user of our website and services, you agree to:
    Provide accurate, current, and complete information as required for accessing our services.
    Use our services only for lawful purposes, and refrain from using them in any way that could harm Wahler Analytics or others.
    Not misuse any portion of the website, including attempting to hack, disable, or otherwise interfere with its functionality or security.
    Follow any additional rules or guidelines for the services you access.
    5. Intellectual Property
    All content on the Wahler Analytics website, including text, images, logos, software, and designs, is the exclusive property of Wahler Analytics or its licensors and is protected under applicable copyright, trademark, and intellectual property laws. You agree not to copy, distribute, modify, or create derivative works based on any portion of the website or its services without explicit written permission from Wahler Analytics.
    6. Prohibited Activities
    You agree not to:
    Engage in any activity that could disrupt or interfere with the services, including using automated scripts, bots, or similar technologies.
    Attempt to gain unauthorized access to any portion of the website or services, including user accounts, systems, or networks.
    Post or distribute any unlawful, harmful, or inappropriate content through our services.
    7. Payment and Fees
    If applicable, fees for using specific Wahler Analytics services will be clearly outlined in service agreements or on our website. You agree to pay any applicable fees promptly and in accordance with the payment terms provided. Wahler Analytics reserves the right to modify fees or introduce new pricing structures. You will be notified in advance of any such changes.
    8. Termination of Services
    We reserve the right to terminate or suspend your access to our website or services for any reason, including but not limited to:
    Breaching these Terms.
    Engaging in fraudulent, illegal, or harmful behavior.
    Interfering with the functionality or security of our services.
    Upon termination, you are prohibited from accessing or using the website or services.
    9. Limitation of Liability
    Wahler Analytics provides its website and services on an "as-is" and "as-available" basis. We do not guarantee that our services will be uninterrupted, error-free, or completely secure.
    To the fullest extent permitted by law, Wahler Analytics disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
    In no event will Wahler Analytics or its affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the website or services.
    10. Indemnification
    You agree to indemnify and hold harmless Wahler Analytics, its affiliates, employees, contractors, and agents from any claims, damages, losses, or expenses (including legal fees) arising out of your use of the website or services, or your breach of these Terms.
    11. Privacy
    Your use of Wahler Analytics is subject to our Privacy Policy. By using the services, you consent to the collection and use of information as described in the Privacy Policy.
    12. Dispute Resolution and Governing Law
    These Terms and any disputes arising from your use of the website or services will be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles.
    You agree to submit to the exclusive jurisdiction of the courts located in Pinellas County, Florida, for the resolution of any disputes arising from these Terms or your use of the services.
    13. Miscellaneous
    Entire Agreement: These Terms, along with the Privacy Policy and any other legal notices published on the website, constitute the entire agreement between you and Wahler Analytics regarding your use of the services.
    Severability: If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
    Waiver: Failure to enforce any right or provision of these Terms does not constitute a waiver of such right or provision.
    14. Contact Information
    If you have any questions or concerns about these Terms, please contact us at:
    Wahler Analytics
    Andrew Wahler
    Email: wahleranalytics@gmail.com
            </div>
            <button onClick={onAccept} disabled={!isScrolledToEnd} style={{
                padding: '10px',
                margin: '10px 0',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: isScrolledToEnd ? '#007bff' : '#ababab',
                color: '#fff',
                cursor: 'pointer',
            }}>
                Accept Terms
            </button>
        </div>
    );
};

export default TermsOfService;
