import React, {useEffect, useState, useContext} from 'react';
import axios from '../utils/service';
import AuthContext from '../context/AuthContext';
import {useRef} from 'react';
import Modal from 'react-modal';
import {Editor} from '@tinymce/tinymce-react';
import {useNavigate, useParams} from "react-router-dom";
import Spinner from "./Spinner";

const AdminPanelEditCategory = () => {
    const {user} = useContext(AuthContext);
    const [category, setCategory] = useState(null);
    const [previewContent, setPreviewContent] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const {categoryId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategory = async () => {
            if (user && user.role === 'admin') {
                const {data} = await axios.get(`/admin/categories/${categoryId}`, {
                    headers: {Authorization: `Bearer ${user.token}`},
                });
                setCategory(data);
                setTitle(data.title);
            }
        };
        fetchCategory();
    }, [user, categoryId]);

    const editorRef = useRef(null);

    const handleTitleChange = (event) => {
        // Update the state with the current input value
        setTitle(event.target.value);
    };
    const previewChanges = async () => {
        setPreviewContent(editorRef.current.getContent());
        setModalIsOpen(true);
    }

    const closeModal = async () => {
        setModalIsOpen(false);
        setPreviewContent('');
    };

    const saveChanges = async () => {
        const body = editorRef.current.getContent();
        console.log(body);
        await axios.post(`/admin/categories/${categoryId}`, {
                _id: category._id,
                title: title,
                body: body,
            },
            {
                headers: {Authorization: `Bearer ${user.token}`},
            }).then((r) => {
            alert('Saved successfully');
            navigate('/admin/categoriess/');
        })
    };


    const styles = {
        container: {
            padding: '20px',
        },
        addButton: {
            padding: '10px 20px',
            margin: '10px',
            backgroundColor: '#35c93d',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
        },
        inputTitle: {
            height: '32px',
            lineHeight: '32px',
            width: '100%',
            fontSize: '24px',
            border: '1px solid #eee'
        },
        cancelButton: {
            backgroundColor: '#c9353d',
            color: '#fff',
            border: 'none',
            padding: '10px 20px',
            margin: '10px',
            cursor: 'pointer',
            borderRadius: '3px',
        },
        containerStyle: {
            display: 'flex',
            justifyContent: 'space-between', // Pushes buttons to opposite ends
            padding: '10px',
        },
        modal: {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '20px',
                zIndex: '1000000',
                overflowY: 'scroll',
                width: '800px',
                height: '600px'
            },
        },
    };

    return (
        <div style={styles.container}>
            {!category ? (
                <Spinner />
            ) : (
                <div>

                </div>
            )}
        </div>
    );
};

export default AdminPanelEditCategory;
