import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect mobile screens

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav style={styles.navbar}>
      {/* Brand for both desktop and mobile */}
      <Link to="/" style={styles.brand}>
        Wahler Analytics
      </Link>

      {/* Desktop Menu */}
      {!isMobile && (
        <div style={styles.desktopLinks}>
          {user ? (
            <>
              <Link to="/dashboard" style={styles.link}>Dashboard</Link>
              {user.role === 'user' && (<Link to="/subscribe" style={styles.link}>Subscribe</Link>)}
              {user.role === 'subscriber' && (
                <>
                  <Link to="/subscribe" style={styles.link}>Your subscription</Link>
                  <Link to="/lessons" style={styles.link}>Lessons</Link>
                </>
              )}
              {user.role === 'admin' && (
                <>
                  <Link to="/admin/users" style={styles.link}>Users</Link>
                  <Link to="/admin/lessons" style={styles.link}>Lessons</Link>
                  <Link to="/admin/categories" style={styles.link}>Categories</Link>
                </>
              )}
              <button onClick={handleLogout} style={styles.button}>Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" style={styles.link}>Login</Link>
              <Link to="/register" style={styles.link}>Register</Link>
            </>
          )}
        </div>
      )}

      {/* Mobile Menu */}
      {isMobile && (
        <>
          <div style={styles.hamburger} onClick={toggleMenu}>
            &#9776;
          </div>
          {menuOpen && (
            <div style={styles.mobileLinks}>
              {user ? (
                <>
                  <Link to="/dashboard" style={styles.link}>Dashboard</Link>
                  {user.role === 'user' && (<Link to="/subscribe" style={styles.link}>Subscribe!</Link>)}
                  {user.role === 'subscriber' && (
                    <>
                      <Link to="/subscribe" style={styles.link}>Your subscription!</Link>
                      <Link to="/lessons" style={styles.link}>Lessons</Link>
                    </>
                  )}
                  {user.role === 'admin' && (
                    <>
                      <Link to="/admin/users" style={styles.link}>Users</Link>
                      <Link to="/admin/lessons" style={styles.link}>Lessons</Link>
                      <Link to="/admin/categories" style={styles.link}>Categories</Link>
                    </>
                  )}
                  <button onClick={handleLogout} style={styles.button}>Logout</button>
                </>
              ) : (
                <>
                  <Link to="/login" style={styles.link}>Login</Link>
                  <Link to="/register" style={styles.link}>Register</Link>
                </>
              )}
            </div>
          )}
        </>
      )}
    </nav>
  );
};

const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 30px',
    backgroundColor: '#333',
    color: '#fff',
    fontSize: '18px',
  },
  brand: {
    textDecoration: 'none',
    color: '#fff',
    fontSize: '24px',
  },
  desktopLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileLinks: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '60px',
    left: '0',
    right: '0',
    backgroundColor: '#333',
    padding: '20px',
  },
  hamburger: {
    display: 'block',
    fontSize: '30px',
    color: '#fff',
    cursor: 'pointer',
  },
  link: {
    marginLeft: '20px',
    textDecoration: 'none',
    color: '#fff',
    marginBottom: '10px', // For spacing in the mobile dropdown
  },
  button: {
    marginLeft: '20px',
    padding: '10px 15px',
    backgroundColor: '#fff',
    color: '#333',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Navbar;
