import React, { useState, useRef } from "react";

const ConfidentialAgreementBox = ({ onAccept }) => {
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const scrollableRef = useRef(null);

    const handleScroll = () => {
        const element = scrollableRef.current;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            setIsScrolledToEnd(true);
        }
    };

    return (
        <div style={{marginTop: '10px', marginBottom: '10px'}}>
            <div
                ref={scrollableRef}
                onScroll={handleScroll}
                style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    padding: "16px",
                    marginBottom: "2px",
                    textAlign: "justify"
                }}
            >
                <h2>CONFIDENTIALITY AGREEMENT</h2>
                <h3>Effective Date: 10/23/2024</h3>
                <p>THIS AGREEMENT ("Agreement") is entered into by and between Wahler Analytics LLC, a Florida limited liability company, hereinafter referred to as (“Wahler Analytics”), and the individual user agreeing to this Agreement ("Player"). In consideration of the mutual covenants and promises set forth herein, and other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the parties agree as follows:</p>
                <ol>
                    <li>Confidentiality The Player agrees that, in connection with receiving services, products, or information from Wahler Analytics, the Player may be exposed to certain proprietary and confidential information, including but not limited to, trade secrets, know-how, business practices, intellectual property, proprietary designs, strategies, data, systems, licenses, patents, trademarks, and other business-related information (collectively, “Proprietary Information”). The Player agrees to maintain such Proprietary Information in strict confidence and will not disclose, share, or use it for any purpose other than as permitted under this Agreement. The Player agrees to take all reasonable steps to protect the confidentiality of the Proprietary Information and prevent unauthorized use or disclosure. </li>
                    <li>Use of Proprietary Information The Player agrees to use the Proprietary Information solely for personal use in connection with playing poker and Wahler Analytics-related services. The Player will not, under any circumstances, use the Proprietary Information for any commercial purposes, distribute it, package, convey, or share it with any third party without the prior written consent of Wahler Analytics.</li>
                    <li>Intellectual Property All rights, title, and interest in and to the Proprietary Information, including but not limited to any copyrights, trademarks, patents, trade secrets, and other intellectual property rights, shall remain the sole and exclusive property of Wahler Analytics. The Player acknowledges that no ownership or interest in the Proprietary Information is transferred to the Player by this Agreement.</li>
                    <li>Breach and Remedies In the event of any actual or threatened breach of this Agreement by the Player, Wahler Analytics shall have the right to seek injunctive relief, specific performance, or any other available legal remedies to prevent or remedy the breach. The Player agrees to notify Wahler Analytics immediately upon becoming aware of any unauthorized disclosure or use of the Proprietary Information and to cooperate fully in mitigating the effects of such breach. The Player agrees to be liable for any damages resulting from the unauthorized disclosure or use of the Proprietary Information, including consequential damages. </li>
                    <li>No Warranties Wahler Analytics makes no representations or warranties regarding the accuracy, completeness, or reliability of the Proprietary Information. The Player acknowledges that the Proprietary Information may still be in development or incomplete, and Wahler Analytics accepts no responsibility or liability for any losses, damages, or costs incurred by the Player as a result of relying on or using the Proprietary Information.</li>
                    <li>Dispute Resolution In the event of any dispute arising under this Agreement, including any dispute regarding its interpretation or enforcement, the parties agree that venue shall lie exclusively in Pinellas County, Florida. The prevailing party in any such dispute shall be entitled to recover its reasonable attorneys’ fees and costs, including fees and costs of any appeal.</li>
                    <li>Termination This Agreement shall remain in full force and effect unless terminated by mutual written agreement of the parties. However, the obligations of confidentiality under this Agreement shall survive termination and remain binding on the Player indefinitely, or until such time as the Proprietary Information becomes public knowledge through no fault of the Player.</li>
                    <li>Miscellaneous
                        <ul>
                            <li>Entire Agreement: This Agreement constitutes the entire agreement between the parties with respect to the subject matter herein and supersedes all prior agreements, understandings, and representations.</li>
                            <li>Amendments: Any modifications or amendments to this Agreement must be in writing and signed by both parties. </li>
                            <li>Severability: If any provision of this Agreement is found to be invalid or unenforceable, the remainder of this Agreement shall remain in full force and effect. </li>
                        </ul>
                    </li>
                </ol>
                <p><strong>By pressing the button, the Player acknowledges they have read, understood, and agreed to be bound by the terms of this Confidentiality Agreement.</strong></p>

            </div>
            <button onClick={onAccept} disabled={!isScrolledToEnd} style={{
                padding: '10px',
                margin: '10px 0',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: isScrolledToEnd ? '#007bff' : '#ababab',
                color: '#fff',
                cursor: 'pointer',
            }}>
                Accept Terms
            </button>
        </div>
    );
};

export default ConfidentialAgreementBox;
