import React, {useEffect, useState, useContext} from 'react';
import axios from '../utils/service';
import AuthContext from '../context/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import Spinner from "./Spinner";
import Modal from 'react-modal';
import {FaEye, FaEyeSlash} from "react-icons/fa";
Modal.setAppElement('#root'); // use your app's root element id here

const AdminPanelCategoriesEditor = () => {
    const {user} = useContext(AuthContext);
    const [categories, setCategories] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [addFormData, setAddFormData] = useState({
        title: '',
        orderOnLessonsPage: 0,
        orderOnDashboardPage: 0
    });
    const [editFormData, setEditFormData] = useState({
        id: '',
        title: '',
        orderOnLessonsPage: 0,
        orderOnDashboardPage: 0
    });
    const navigate = useNavigate();
    const fetchCategories = async () => {
        if (user && user.role === 'admin') {
            const {data} = await axios.get(`/admin/categories`, {
                headers: {Authorization: `Bearer ${user.token}`},
            });
            setCategories(data);
        }
    };
    useEffect(() => {
        fetchCategories();
    }, [user]);

    const deleteCategory = async (categoryId) => {
        const answer = window.confirm("Are you sure you want to delete?");
        if(answer) {
            if (user && user.role === 'admin') {
                const {data} = await axios.delete(`/admin/categories/${categoryId}`, {
                    headers: {Authorization: `Bearer ${user.token}`},
                });
                setCategories(categories.filter(c => c.id !== data.id));
            }
        }
    };
    const editCategory = async (category) => {
        if (user && user.role === 'admin') {
            const {data} = await axios.put(`/admin/categories/${category.id}`, editFormData, {
                headers: {Authorization: `Bearer ${user.token}`},
            });
            await fetchCategories();
            await closeEditModal();
        }
    };
    const addCategory = async () => {
        if (user && user.role === 'admin') {
            const {data} = await axios.post(`/admin/categories/`, addFormData, {
                headers: {Authorization: `Bearer ${user.token}`},
            });
            setCategories([...categories, data]);
            await closeAddModal();
        }
    };

    const handleAddChange = async (e) => {
        const {name, value} = e.target;
        setAddFormData(prevData => ({
            ...prevData,
            [name]: value || ''
        }));
    };

    const handleEditChange = async (e) => {
        const {name, value} = e.target;
        setEditFormData(prevData => ({
            ...prevData,
            [name]: value || ''
        }));
    };

    const handleAddNewCategory = async (e) => {
        e.preventDefault();
        await addCategory();
    }

    const handleEditCategory = async (e) => {
        e.preventDefault();
        await editCategory(editFormData);
    }

    const openAddModal = async () => {
        setAddFormData({name: '', orderOnLessonsPage: 0, orderOnDashboardPage: 0});
        setAddModalIsOpen(true);
    };

    const closeAddModal = async () => {
        setAddModalIsOpen(false);
    };

    const openEditModal = async (category) => {
        setEditFormData({id: category._id, title: category.title, orderOnLessonsPage: category.orderOnLessonsPage, orderOnDashboardPage: category.orderOnDashboardPage});
        setEditModalIsOpen(true);
    };

    const closeEditModal = async () => {
        setEditModalIsOpen(false);
    };

    const styles = {
        container: {
            padding: '20px',
        },
        editButton: {
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '5px',
        },
        deleteButton: {
            backgroundColor: '#ff1f33',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '5px',
        },
        addButton: {
            marginBottom: '20px',
            padding: '10px 15px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
        },
        confirmButton: {
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            padding: '10px 20px',
            margin: '10px',
            cursor: 'pointer',
            borderRadius: '3px',
        },
        cancelButton: {
            backgroundColor: '#6c757d',
            color: '#fff',
            border: 'none',
            padding: '10px 20px',
            margin: '10px',
            cursor: 'pointer',
            borderRadius: '3px',
        },
        table: {
            width: '100%', borderCollapse: 'collapse', marginTop: '20px',
        },
        th: {
            borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#333', color: '#fff',
        },
        td: {
            borderBottom: '1px solid #ddd', padding: '10px',
        },
        modal: {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '20px',
                textAlign: 'center',
                minWidth: '300px'
            },
        },
        label: {
            display: 'block',
            marginBottom: '5px',
        },
        input: {
            width: '100%',
            padding: '8px',
            boxSizing: 'border-box',
        },
    };


    return (
        <div style={styles.container}>
            <h1>Admin Panel</h1>
            <h2>Categories</h2>
            {!categories ? (
                <Spinner/>
            ) : (
                <>
                    <button onClick={openAddModal} style={styles.addButton}>Add Category</button>
                    <table style={styles.table}>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {categories.map((category) => (<tr key={category._id}>
                            <td style={styles.td}>{category._id.slice(-8)}</td>
                            <td style={styles.td}>{category.title}</td>
                            <td style={styles.td}>
                                <button style={styles.editButton} onClick={() => openEditModal(category)}>Edit</button>
                                <button style={styles.deleteButton} onClick={() => deleteCategory(category._id)}>delete</button>
                            </td>
                        </tr>))}
                        </tbody>
                    </table>
                </>
            )}

            <Modal
                isOpen={addModalIsOpen}
                onRequestClose={closeAddModal}
                style={styles.modal}
                contentLabel="Add Category Modal"
            >
                <h2>Add Category</h2>
                <form onSubmit={handleAddNewCategory}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Name:</label>
                        <input style={styles.input} type="text" name="title" defaultValue=""
                               onChange={handleAddChange} required />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Order on Lessons Page:</label>
                        <input style={styles.input} type="number" min="0" max="999" name="orderOnLessonsPage" defaultValue={0}
                               onChange={handleAddChange} required />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Order on Dashboard Page:</label>
                        <input style={styles.input} type="number" min="0" max="999" name="orderOnDashboardPage" defaultValue={0}
                               onChange={handleAddChange} required />
                    </div>
                    <button type="submit" style={styles.confirmButton}>Add Category</button>
                    <button type="button" style={styles.cancelButton} onClick={closeAddModal}>Cancel</button>
                </form>
            </Modal>


            <Modal
                isOpen={editModalIsOpen}
                onRequestClose={closeEditModal}
                style={styles.modal}
                contentLabel="Edit Category Modal"
            >
                <h2>Edit Category</h2>
                <form onSubmit={handleEditCategory}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Name:</label>
                        <input style={styles.input} type="text" name="title" value={editFormData.title}
                               onChange={handleEditChange} required />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Order on Lessons Page:</label>
                        <input style={styles.input} type="number" min="0" max="999" name="orderOnLessonsPage" value={editFormData.orderOnLessonsPage}
                               onChange={handleEditChange} required />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Order on Dashboard Page:</label>
                        <input style={styles.input} type="number" min="0" max="999" name="orderOnDashboardPage" value={editFormData.orderOnDashboardPage}
                               onChange={handleEditChange} required />
                    </div>
                    <button type="submit" style={styles.confirmButton}>Edit Category</button>
                    <button type="button" style={styles.cancelButton} onClick={closeEditModal}>Cancel</button>
                </form>
            </Modal>
        </div>);
};
export default AdminPanelCategoriesEditor;
